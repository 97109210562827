import { useHomeStore } from "@/stores";
import axios from "axios";
import router from "@/router";

const instance = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL,
});

if (localStorage.getItem("userData")) {
  const userData = JSON.parse(localStorage.getItem("userData")!);
  const token = userData.token;
  instance.defaults.headers.common["Authorization"] = `Bearer ${token}`;
}

instance.interceptors.request.use((config) => {
  console.log("Making request to " + config.url);
  // const homeStore = useHomeStore();

  // if (!navigator.onLine) {
  //   homeStore.toast = {
  //     status: true,
  //     type: "error",
  //     message: "Please check your Internet Connection",
  //   };
  // }
  return config;
});

instance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    const homeStore = useHomeStore();
    if (error.response.status === 401) {
      homeStore.toast = {
        status: true,
        type: "error",
        message:
          error.response.data.message ||
          "Session expired. Please login to continue.",
      };

      router.push("/");
    }

    // console.log("response error", error);
    return Promise.reject(error);
  }
);

export default instance;
